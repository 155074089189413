import React, { useState, useEffect } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import {
  englishToBengaliNumberFormat,
  topicType,
} from "../../../../../../utilities/utilities";
import { Link, useNavigate } from "react-router-dom";
import { CiLock } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import { useCountdown } from "../../../../../user/shared/CountDown/CountDown";

const CoursePlaylist = ({ lessons, topic, courseId, batchId, index }) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const [syllabus, setSyllabus] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const navigate = useNavigate();
  const [allLessons, setAllLessons] = useState(lessons);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? index : index);
  };

  const fetchSyllabusesInfo = async (courseId, batchId, courseSubId) => {
    try {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }course-syllabus?batch_id=${batchId}&course_id=${courseId}&user_id=${localStorage.getItem(
          "user_id"
        )}&course_subject_id=${courseSubId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      return response.data.syllabuses.lessons;
    } catch (error) {
      console.error("Error fetching syllabus:", error);
      return null;
    }
  };

  const fetchSyllabus = async (topicId) => {
    try {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }get-topic-assesment?topic_id=${topicId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      return response.data.topic;
    } catch (error) {
      console.error("Error fetching syllabus:", error);
      return null;
    }
  };

  const fetchProgressData = async (batchId, courseId, courseSubId) => {
    try {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }get-progress-percent?batch_id=${batchId}&course_id=${courseId}&user_id=${localStorage.getItem(
          "user_id"
        )}&course_subject_id=${courseSubId}`,
        {
          method: "GET",
          headers: {
            contentType: "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      return response;
    } catch (error) {
      console.error("Error fetching progressData:", error);
    }
  };

  const handleAssessment = async (topicId, lessonName, topicIndex, index) => {
    setSelectedTopic(topicId);
    const fetchedSyllabus = await fetchSyllabus(topicId);
    setSyllabus(fetchedSyllabus);

    if (!fetchedSyllabus) return;

    let topicInfo;

    if (fetchedSyllabus.assesment_type === 6) {
      topicInfo = {
        course_id: fetchedSyllabus?.lecture?.course_id,
        user_id: localStorage.getItem("user_id"),
        batch_id: fetchedSyllabus?.lecture?.batch_id,
        course_subject_id: fetchedSyllabus?.lecture?.course_subject_id,
        topic_id: topicId,
      };
    } else if (fetchedSyllabus.assesment_type === 5) {
      topicInfo = {
        course_id: fetchedSyllabus?.smart_note?.course_id,
        user_id: localStorage.getItem("user_id"),
        batch_id: fetchedSyllabus?.smart_note?.batch_id,
        course_subject_id: fetchedSyllabus?.smart_note?.subject_id,
        topic_id: topicId,
      };
    } else if (fetchedSyllabus.assesment_type === 3) {
      topicInfo = {
        course_id: fetchedSyllabus?.assignment?.course_id,
        user_id: localStorage.getItem("user_id"),
        batch_id: fetchedSyllabus?.assignment?.batch_id,
        course_subject_id: fetchedSyllabus?.assignment?.subject_id,
        topic_id: topicId,
      };
    }

    try {
      await fetch(
        `${process.env.REACT_APP_BASE_URL_STUDENT}view-recorded-content`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
          body: JSON.stringify(topicInfo),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200 && data.message_type === "success") {
          }
        });
    } catch (error) {
      console.error("Error fetching recorded content:", error);
    }

    const allSyllabuseInfo = await fetchSyllabusesInfo(
      topicInfo.course_id,
      topicInfo.batch_id,
      topicInfo.course_subject_id
    );
    setAllLessons(allSyllabuseInfo);

    const progress = await fetchProgressData(
      topicInfo.batch_id,
      topicInfo.course_id,
      topicInfo.course_subject_id
    );
    const progressPercent = progress?.data?.progress_percent;

    const navigateTo = (path, state) => {
      navigate(path, { state });
    };

    const commonState = {
      course_id: fetchedSyllabus?.lecture?.course_id,
      batch_id: fetchedSyllabus?.lecture?.batch_id,
      lesion_name: lessonName,
      topic_name: fetchedSyllabus.topic_name,
      lessons: allSyllabuseInfo,
      index: index,
      topic,
      progressPercent,
    };

    if (fetchedSyllabus?.assesment_type === 6) {
      navigateTo("/view-lecture?class_content=content_list", {
        ...commonState,
        lecture_id: fetchedSyllabus?.lecture?.id,
      });
    } else if (fetchedSyllabus?.assesment_type === 5) {
      navigateTo("/view-smart-note?class_content=content_list", {
        ...commonState,
        smartNoteLink: fetchedSyllabus?.smart_note_pdf_link,
        smart_note_pdf_file: fetchedSyllabus?.smart_note?.smart_note_pdf_file,
      });
    } else if (
      [1, 2, 3, 7].includes(fetchedSyllabus?.assesment_type) &&
      fetchedSyllabus?.assignment
    ) {
      if (
        fetchedSyllabus?.assignment?.question_show_mode === 1 &&
        fetchedSyllabus?.assignment?.no_of_attempts -
          fetchedSyllabus?.assignment?.total_attempts >
          0
      ) {
        navigateTo("/assignment-question", {
          ...commonState,
          assignment_id: fetchedSyllabus?.assignment?.id,
        });
      } else if (fetchedSyllabus?.assignment?.question_show_mode === 2) {
        navigateTo("/assignment-questions", {
          ...commonState,
          assignment_id: fetchedSyllabus?.assignment?.id,
        });
      } else if (
        fetchedSyllabus?.assignment?.no_of_attempts >=
          fetchedSyllabus?.assignment?.total_attempts &&
        fetchedSyllabus?.assignment?.total_attempts !== 0
      ) {
        navigateTo("/view-submission?class_content=content_list", {
          ...commonState,
          assignment_id: fetchedSyllabus?.assignment?.id,
          total_attempt: fetchedSyllabus?.assignment?.total_attempts,
          no_of_attempts: fetchedSyllabus?.assignment?.no_of_attempts,
          subject: fetchedSyllabus?.assignment.subject_name,
          total_questions: fetchedSyllabus?.assignment?.total_questions,
          total_marks: fetchedSyllabus?.assignment?.total_marks,
        });
      }
    }
  };

  return (
    <div className="tw-rounded-lg accordion">
      {allLessons.map((lesson, index) => (
        <div
          key={index}
          className={`tw-rounded-lg accordion-item 
            ${
              lesson.topics[0]?.is_viewed ||
              lesson.topics[1]?.is_viewed ||
              lesson.topics[2]?.is_viewed
                ? "tw-bg-blue-200"
                : ""
            }`}
        >
          <button
            className={`tw-p-4 tw-text-left tw-w-full tw-flex tw-items-center tw-justify-between tw-transition tw-duration-300 tw-ease-in-out focus:tw-outline-none tw-border-none`}
            onClick={() => toggleAccordion(index)}
          >
            <p>
              <span className="tw-bg-[#FFCE39] tw-mr-4 tw-px-4 tw-py-2 tw-rounded-xl tw-font-semibold">
                {englishToBengaliNumberFormat(index + 1)}
              </span>
              <span className="tw-text-lg tw-font-semibold">
                {lesson?.lesson_name}
              </span>
            </p>
            {activeIndex === index ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </button>
          {activeIndex === index && (
            <div>
              <hr className="tw-border-gray-50" />
              <div className="accordion-content tw-p-4">
                {lesson.topics.map((topic, topicIndex) => {
                  const previousTopicViewed =
                    topicIndex === 0 ||
                    lesson.topics[topicIndex - 1]?.is_viewed;
                  return (
                    <div key={topicIndex}>
                      <div
                        onClick={() =>
                          previousTopicViewed &&
                          handleAssessment(
                            topic?.topic_id,
                            lesson?.lesson_name,
                            topicIndex,
                            index
                          )
                        }
                        className={`tw-text-sm tw-mb-4 tw-flex tw-cursor-pointer tw-transition tw-duration-300 tw-ease-in-out 
                                        ${
                                          !topic?.is_viewed
                                            ? "tw-opacity-50"
                                            : "tw-bg-blue-200 tw-p-4 tw-rounded-lg"
                                        }
                                        ${
                                          selectedTopic === topic?.topic_id
                                            ? "tw-bg-blue-200 tw-p-4 tw-rounded-lg"
                                            : ""
                                        }
                                        ${
                                          !previousTopicViewed
                                            ? "tw-cursor-not-allowed tw-opacity-50"
                                            : ""
                                        }`}
                      >
                        {topic?.is_viewed ? (
                          <div className="tw-bg-green-400 tw-p-1 tw-mr-4 tw-rounded-lg">
                            <MdDone className="tw-text-lg tw-text-white" />
                          </div>
                        ) : (
                          <CiLock className="tw-text-2xl tw-mr-4" />
                        )}
                        <div className="tw-flex">
                          {topicType(topic?.assesment_type)}:
                        </div>
                        <div className="tw-ml-2 tw-text-md">
                          {topic?.topic_name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CoursePlaylist;
