import React, { useEffect, useState } from "react";
import TopCircle from "../../../../../../images/top_circle.png";
import BottomCircle from "../../../../../../images/bottom_circle.png";
import CircleIcon from "../../../../../../images/arrow_white.png";
import "./CourseEnroll.css";
import {
  englishToBengaliNumberFormat,
  englishToBengaliNumberFormatWithZero,
} from "../../../../../../utilities/utilities";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import TagManager from "react-gtm-module";
import utils from "../../../../../../utils/index";
import LiveClass from "../../../../../../images/course_details_icon/Liveclass.png";
import Level from "../../../../../../images/course_details_icon/level.png";
import Subject from "../../../../../../images/course_details_icon/subject.png";
import batch from "../../../../../../images/course_details_icon/batchIcon.png";
import oneToOne from "../../../../../../images/course_details_icon/oneToOne.png";
import { HiCheckCircle, HiVideoCamera, HiUserGroup } from "react-icons/hi";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const plans = [
  {
    name: "ব্যাচ ভিত্তিক অনলাইন ক্লাস",
    details:
      "সীমিত সংখ্যাক শিক্ষার্থী, মজার রিডিং ম্যাটারিয়ালস এবং এক্সপার্ট টিচার্স  নিয়ে লাইভ ইন্টারেক্টিভ ক্লাসরুম।",
    image: batch,
  },
  {
    name: "ওয়ান টু ওয়ান লাইভ কাস্টমাইজড লার্নিং",
    details:
      "পার্সনালাইজড টিচার ও রিডিং ম্যাটারিয়াল নিয়ে হবে তোমার পার্সনালাইজড লার্নিং। ",
    image: oneToOne,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CourseEnroll = ({
  courseDetail,
  isCoursePurchase,
  id,
  affiliate_id,
  open,
  courseSubjectCount,
  isOneToOneCourse,
  handleTimeScheduleRef,
  setIsSelectOneToOne,
  course_features,
}) => {
  const displayedPlans = isOneToOneCourse
    ? plans
    : plans.filter(
        (plan) => plan.name !== "ওয়ান টু ওয়ান লাইভ কাস্টমাইজড লার্নিং"
      );
  const [focus, setFocus] = useState(plans[0]);
  let encoded = base64_encode(`${id}`);
  const sendGTMInfo = () => {
    const user = localStorage.getItem("auth_token");
    if (!user) {
      TagManager.dataLayer({
        dataLayer: {
          event: "courseRegistration",
          pagePath: "New login",
          PageTitle: "New login",
          course: courseDetail?.course_name,
          price: courseDetail?.course_current_price,
          currency: "BDT",
        },
      });
      const pixelData = { page: `${courseDetail?.course_name} course` };
      utils.addFacebookEvent("Course Registration", pixelData);
    }
  };

  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");

  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }
  //Translation End

  console.log("Course Mood=>", courseDetail);

  return (
    <div>
      <div className="tw-border tw-rounded-3xl">
        <div className="tw-flex tw-justify-between tw-bg-gray-100 tw-p-4 tw-rounded-tl-3xl tw-rounded-tr-3xl">
          <p className="tw-text-xl tw-font-bold">{t("Course Overview")}</p>
          <div className="tw-flex tw-items-center tw-px-6">
            <div className="tw-mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z"
                  stroke="#6B7280"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.68387 13.3419C8.88616 12.9381 9 12.4824 9 12C9 11.5176 8.88616 11.0619 8.68387 10.6581M8.68387 13.3419C8.19134 14.3251 7.17449 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.17449 9 8.19134 9.67492 8.68387 10.6581M8.68387 13.3419L15.3161 16.6581M8.68387 10.6581L15.3161 7.34193M15.3161 7.34193C15.8087 8.32508 16.8255 9 18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 6.48237 15.1138 6.93815 15.3161 7.34193ZM15.3161 16.6581C15.1138 17.0619 15 17.5176 15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.8255 15 15.8087 15.6749 15.3161 16.6581Z"
                stroke="#6B7280"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="tw-flex tw-justify-around tw-px-4 tw-mt-3">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mr-3 tw-px-5 tw-py-3">
            <img src={LiveClass} />
            <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mt-2">
              {courseDetail?.course_total_live_class}টি
            </p>
            <p className="tw-text-gray-500 ">{t("Live ")}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mr-3 tw-px-5 tw-py-3">
            <img src={Level} />
            <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mt-2">
              {courseDetail?.course_total_level}টি
            </p>
            <p className="tw-text-gray-500 ">লেভেল</p>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mr-3 tw-px-5 tw-py-3">
            <img src={Subject} />
            <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mt-2">
              {courseSubjectCount}টি
            </p>
            <p className="tw-text-gray-500 ">বিষয়</p>
          </div>
        </div>

        <div className="tw-flex tw-flex-wrap tw-px-6 tw-mt-2">
          {course_features &&
            course_features.map((item, idx) => (
              <div>
                <p key={idx} className="tw-flex tw-items-center tw-p-2 ">
                  <HiCheckCircle className="tw-mr-2" />
                  <span>{item?.course_feature}</span>
                </p>
              </div>
            ))}
        </div>


        {courseDetail?.course_mode_id == 2 ? (
          ""
        ) : (
          <div className="tw-px-6 ">
            <RadioGroup value={focus} onChange={setFocus}>
              <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-y-6 tw-sm:grid-cols-3 tw-sm:gap-x-4">
                {displayedPlans.map((plan) => (
                  <RadioGroup.Option
                    onClick={
                      plan.name === "ওয়ান টু ওয়ান লাইভ কাস্টমাইজড লার্নিং"
                        ? () => setIsSelectOneToOne(true)
                        : () => setIsSelectOneToOne(false)
                    }
                    key={plan.name}
                    value={plan}
                    className={({ focus }) =>
                      classNames(
                        focus
                          ? "tw-border-[#6F39B7] tw-ring-2 tw-ring-[#6F39B7]"
                          : "tw-border-gray-300",
                        "tw-relative tw-flex tw-cursor-pointer tw-rounded-lg tw-border tw-bg-white tw-p-4 tw-shadow-sm tw-focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="tw-flex tw-flex-1">
                          <div className="tw-w-full tw-flex tw-justify-between">
                            <div className=" tw-mr-2">
                              <img
                                className="tw-w-12"
                                src={plan.image}
                                alt="image"
                              />
                            </div>
                            <div className="">
                              <RadioGroup.Label
                                as="span"
                                className="tw-font-medium tw-text-gray-900"
                              >
                                {plan.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="tw-mt-1 tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                              >
                                {plan.details}
                              </RadioGroup.Description>
                            </div>
                          </div>
                        </span>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "tw-invisible" : "",
                            "tw-h-5 tw-w-5 tw-text-[#6F39B7]"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? "tw-border" : "tw-border-2",
                            checked
                              ? "tw-border-[#6F39B7]"
                              : "tw-border-transparent",
                            "tw-pointer-events-none tw-absolute tw--inset-px tw-rounded-lg"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}

        <div className="tw-flex tw-justify-between tw-px-6 tw-mt-10 tw-pb-6">
          {/*<button*/}
          {/*    className="tw-flex tw-items-center tw-border tw-border-[#532D80] tw-px-5 tw-py-3 tw-rounded-3xl">*/}
          {/*    <HiVideoCamera className="tw-mr-2 tw-w-6 tw-h-6 tw-text-[#532D80]"></HiVideoCamera>*/}
          {/*    <span className="tw-text-[#532D80] tw-font-bold">ফ্রি ডেমো ক্লাস</span>*/}
          {/*</button>*/}
          {focus?.name === "ওয়ান টু ওয়ান লাইভ কাস্টমাইজড লার্নিং" ? (
            <>
              {
                <button
                  className="tw-px-5 tw-py-3 tw-bg-[#532D80] tw-rounded-3xl tw-text-white tw-font-bold"
                  onClick={handleTimeScheduleRef}
                >
                  পছন্দমত সময় দেখো
                </button>
              }
            </>
          ) : (
            <>
              {isCoursePurchase ? (
                <p className="mb-0">ইতিমধ্যে ক্রয়কৃত</p>
              ) : affiliate_id ? (
                <button
                  className="tw-px-5 tw-py-3 tw-bg-[#532D80] tw-rounded-3xl tw-text-white tw-font-bold"
                  onClick={() => {
                    sendGTMInfo();
                    open(true);
                  }}
                >
                  এখনই ভর্তি হও
                </button>
              ) : (
                <button
                  className="tw-px-5 tw-py-3 tw-bg-[#532D80] tw-rounded-3xl tw-text-white tw-font-bold"
                  onClick={() => {
                    sendGTMInfo();
                    open(true);
                  }}
                >
                  এখনই ভর্তি হও
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseEnroll;
