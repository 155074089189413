import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageTitle from "../../shared/PageTitle/PageTitle";
import Banner from "./Banner/Banner";
import Card from "./Card/Card";
import Loader from "../../shared/Loader/Loader";
import FreeDemoClass from "../../shared/FreeDemoClass/FreeDemoClass";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import utils from "../../../../utils/index";

const Genius = () => {
  const [categories, setCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  /* const [count, setCount] = useState(3); */
  const location = useLocation();
  const { state } = location;

  /* const handelLoadCourse = () =>{
      let addCount = parseInt(count) + 3;
      setCount(addCount);
  } */

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageView",
        pagePath: location.pathname,
        PageTitle: "Genius",
      },
    });
  }, [location]);


  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/course-category-wings/${state.from}`
      );
      response = await response.json();
      setCategories(response.data);
      setIsLoading(false);
    }
    fetchMyAPI();
    let advancedMatching = {};
    if (localStorage.getItem("mobile_or_email")) {
      advancedMatching = {
        ph: Number(localStorage.getItem("mobile_or_email")),
        external_id: localStorage.getItem("user_id"),
      };
    }

    const pixelData = { page: "Genius" };
    utils.addFacebookEvent("Page View", pixelData, advancedMatching);

    ReactGA.event({
      category: `${state.from}`,
      action: `${state.from}-action`,
      label: `${state.from}-level`, // optional
      value: localStorage.getItem("mobile_or_email")
        ? Number(localStorage.getItem("mobile_or_email"))
        : undefined, // optional, must be a number
    });

    mixpanel.track(`${state.from}_visit`, {
      number: localStorage.getItem("mobile_or_email")
        ? Number(localStorage.getItem("mobile_or_email"))
        : "Not Registered User",
    });
  }, [state.from]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ paddingBottom: "20rem" }}>
      <PageTitle title="Genius"></PageTitle>
      {/* <Header></Header> */}
      <FreeDemoClass />
      <Banner></Banner>
      
      <ul
        className="nav nav-pills mb-3 mt-5 d-flex justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        {categories?.course_categories?.map((category) => {
          return category?.course_sub_categories?.map((subCategory, index) => (
            <li className="nav-item mx-2 py-1" role="presentation" key={index}>
              <button
                className={`nav-link tabpen-links category-name py-1 ${
                  index === 0 ? "active" : ""
                }`}
                id={`pills-category_${subCategory.course_sub_category_id}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#pills-category_${subCategory.course_sub_category_id}`}
                type="button"
                role="tab"
                aria-controls={`pills-category_${subCategory.course_sub_category_id}`}
                aria-selected="true"
              >
                {subCategory.course_sub_category_name}
              </button>
            </li>
          ));
        })}
      </ul>

      <div className="tab-content mt-5 mb-5" id="pills-tabContent">
        {categories?.course_categories?.map((category) => {
          return category?.course_sub_categories?.map((subCategory, index) => {
            return (
              <div
                className={`tab-pane fade ${index === 0 ? "active show" : ""}`}
                id={`pills-category_${subCategory.course_sub_category_id}`}
                role="tabpanel"
                aria-labelledby={`pills-category_${subCategory.course_sub_category_id}-tab`}
                key={subCategory.course_sub_category_id}
              >
                <div className="container mt-5 mb-5">
                  <div className="row gy-4">
                    {categories?.courses?.map((course, index) => {
                      if (
                        subCategory?.course_sub_category_id ===
                        course?.course_sub_category_id
                      ) {
                        return (
                          <Card
                            key={index}
                            course={course}
                            path_link={categories?.course_image_path}
                          ></Card>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                <p className="text-center mb-4" style={{ color: "#BDBCBE" }}>
                  আপনি তালিকার শেষে পৌঁছে গিয়েছেন
                </p>
                {/* <div className="d-flex justify-content-center">
                            <button className=" btn-seeMore px-4 py-2 " style={categories.courses?.length > 0 && count < categories.courses?.length  ? { display: "block" } : { display: "none" }} onClick={handelLoadCourse}>আরো দেখুন</button>
                        </div> */}
              </div>
            );
          });
        })}
      </div>
      {/* <Message></Message>
                <Footer></Footer> */}
    </div>
  );
};

export default Genius;
