import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
/* import Student from "./components/student/Student"; */
import "./components/student/Student.css";
import "react-loading-skeleton/dist/skeleton.css";
import FeedbackCheck from "./components/student/Dashboard/Feedback/FeedbackCheck";
import Calendar from "./components/student/Dashboard/Calendar/Calendar";
import Dashboard from "./components/student/Dashboard/Dashboard";
import LatestCourses from "./components/student/Dashboard/LatestCourses/LatestCourses";
import LiveClass from "./components/student/Dashboard/LiveClass/LiveClass";
import MainContent from "./components/student/Dashboard/MainContent/MainContent";
import MyBooks from "./components/student/Dashboard/MyBooks/MyBooks";
import MyCourse from "./components/student/Dashboard/MyCourse/MyCourse";
import Profile from "./components/student/Dashboard/Profile/Profile";
import CanNotJoinClass from "./components/student/Pages/CanNotJoinClass/CanNotJoinClass";
import Certificate from "./components/student/Pages/Certificate/Certificate";
import AssignmentQuestionShow from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/AssignmentQuestionShow";
import FileSubmission from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/FileSubmission/FileSubmission";
import AssignmentQuestionShowAtaTime from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShowAtaTime/AssignmentQuestionShowAtaTime";
import AssignmentSubmissionView from "./components/student/Pages/LMS/Assignment/AssignmentSubmissionView/AssignmentSubmissionView";
import AssignmentSubmitAutoCount from "./components/student/Pages/LMS/Assignment/AssignmentSubmitAutoCount/AssignmentSubmitAutoCount";
import AssignmentSubmitDone from "./components/student/Pages/LMS/Assignment/AssignmentSubmitDone/AssignmentSubmitDone";
import LectureView from "./components/student/Pages/LMS/Lectures/Lecture/LectureView/LectureView";
import LMS from "./components/student/Pages/LMS/LMS";
import SmartNoteView from "./components/student/Pages/LMS/SmartNotes/SmartNote/SmartNoteView/SmartNoteView";
import PaymentFailed from "./components/student/Pages/PaymentFailed/PaymentFailed";
import PaymentSuccess from "./components/student/Pages/PaymentSuccess/PaymentSuccess";
import TransactionTable from "./components/student/Pages/TransactionTable/TransactionTable";
import RequireAuth from "./components/user/pages/Login/RequireAuth/RequireAuth";
import User from "./components/user/User";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";
import PaidStudentInfo from "./components/user/pages/LeadCollect/PaidStudentInfo/PaidStudentInfo";
import OrganicAudienceForm from "./components/user/pages/LeadCollect/OrganicAudienceForm/OrganicAudienceForm";
import PaidMarketingStudentInformation from "./components/user/pages/LeadCollect/PaidMarketingStudentInformation/PaidMarketingStudentInformation";
import BkashLeads from "./components/user/pages/LeadCollect/BkashLeads/BkashLeads";
import BkashLeadsList from "./components/user/pages/LeadCollect/BkashLeads/BkashLeadsList";
import Registration from "./components/user/pages/Registration/Registration";
import RegistrationLeads from "./components/user/pages/LeadCollect/RegistrationLeads/RegistrationLeads";
import CadetVivaRegistration from "./components/user/pages/CadetVivaRegistration/CadetVivaRegistration";
import CadetVivaRegistrationList from "./components/user/pages/CadetVivaRegistration/CadetVivaRegistrationList";
import utils from "./utils/index";
import MoreInfo from "./components/user/pages/LeadCollect/MoreInfo/MoreInfo";
import GiftCard from "./components/student/Dashboard/GiftCard/GiftCard";
import MoreInfoLeads from "./components/user/pages/LeadCollect/MoreInfoLeads/MoreInfoLeads";
import AddToCart from "./components/user/shared/AddToCart/AddToCart";
import CoursePayment from "./components/user/shared/AddToCart/CoursePayment/CoursePayment";
import SuccessfulPayment from "./components/user/shared/SuccessfulPayment/SuccessfulPayment";
import CourseDetails from "./components/user/shared/CourseDetails/CourseDetails";
import AbacusCampaign from "./components/user/pages/LeadCollect/ AbacusCampaign/AbacusCampaign";
import Result from "./components/user/pages/LeadCollect/ AbacusCampaign/Result/Result";
import EFT from "./components/user/pages/LeadCollect/EFT/EFT";
import EftRegistrationList from "./components/user/pages/LeadCollect/EFT/EFTRegistrationList";
import AbacusCampaignLeads from "./components/user/pages/LeadCollect/ AbacusCampaign/AbacusCampaignLeads";
import StudentFeedback from "./components/student/Dashboard/Feedback/StudentFeedback";
import DownloadRegistrationLeads from "./components/user/pages/LeadCollect/DownloadRegistrationLeads/DownloadRegistrationLeads";
import CourseInfo from "./components/user/pages/LeadCollect/CourseInfo/CourseInfo";
import { messaging, generateToken } from "./notifications/firebase.js";
import { onMessage } from "firebase/messaging";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`);
mixpanel.init(`${process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN}`, {
  debug: true,
});

function App() {
  const currentLink = window.location.href;

  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await generateToken();
        // console.log("User FCM Token:", token);
      } else {
        console.log("Unable to get permission to notify.");
      }
    };

    requestPermission();

    // Handle foreground messages
    onMessage(messaging, (payload) => {
      // console.log("Message received in foreground: ", payload);
      // Show native push notification
      if (Notification.permission === "granted") {
        console.log("******** Notifications********");
        // console.log(payload.data);

        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.body,
          icon: payload.data.image,
        };
        new Notification(notificationTitle, notificationOptions);
      }
    });
  }, []);

  useEffect(() => {
    utils.addFacebookPageView();
    const tagManagerArgs = {
      gtmId: "GTM-WQLQXZR5",
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "Custom Title",
  });

  return (
    <div className="h-100">
      <Routes>
        <Route path="*" element={<User />}></Route>

        <Route element={<Dashboard></Dashboard>}>
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  <MainContent />
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="my-course"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <MyCourse />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="live-class"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <LiveClass />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="calendar"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <Calendar />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="my-books"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <MyBooks />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />

          <Route
            path="profile"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <Profile />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="transaction-info"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <TransactionTable />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="my-course/lms"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <LMS />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="view-submission"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <AssignmentSubmissionView />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="assignment-question"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <AssignmentQuestionShow />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="view-lecture"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <LectureView />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="submit-assignment-with-marks"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <AssignmentSubmitAutoCount />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
          <Route
            path="view-smart-note"
            element={
              <RequireAuth>
                <FeedbackCheck>
                  {" "}
                  <SmartNoteView />{" "}
                </FeedbackCheck>
              </RequireAuth>
            }
          />
        </Route>

        <Route path="course-payment" element={<CoursePayment />} />
        <Route path="success-payment" element={<SuccessfulPayment />} />
        {/* <Route path="payment-success" element={<PaymentSuccess />} /> */}
        <Route path="payment-success" element={<PaymentSuccess />} />
        {/* <Route path="payment-failed" element={<PaymentFailed />} /> */}
        <Route path="payment-failed" element={<PaymentFailed />} />

        <Route
          path="assignment-questions"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <AssignmentQuestionShowAtaTime />
              </FeedbackCheck>
            </RequireAuth>
          }
        />
        <Route
          path="submit-assignment"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <AssignmentSubmitDone />
              </FeedbackCheck>
            </RequireAuth>
          }
        />
        <Route
          path="image-show"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <FileSubmission />
              </FeedbackCheck>
            </RequireAuth>
          }
        />
        <Route
          path="join-class-issue"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <CanNotJoinClass />
              </FeedbackCheck>
            </RequireAuth>
          }
        ></Route>

        <Route
          path="certificate"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <Certificate />
              </FeedbackCheck>
            </RequireAuth>
          }
        ></Route>

        {/* Lead Collect */}
        <Route
          path="student-information"
          element={
            <RequireAuth>
              <FeedbackCheck>
                <PaidStudentInfo />
              </FeedbackCheck>
            </RequireAuth>
          }
        />
        <Route path="organic-audience-info" element={<OrganicAudienceForm />} />

        <Route
          path="paid-marketing-student-information-form"
          element={<PaidMarketingStudentInformation />}
        />
        <Route path="bkash" element={<BkashLeads />} />
        <Route path="bkash-leads" element={<BkashLeadsList />} />
        <Route path="registration" element={<RegistrationLeads />} />
        <Route path="sign-up" element={<Registration />} />
        <Route path="/cadet-viva" element={<CadetVivaRegistration />} />
        <Route
          path="/cadet-viva-list"
          element={<CadetVivaRegistrationList />}
        />
        <Route path="/info" element={<CourseInfo />} />
        <Route path="latest-courses" element={<LatestCourses />} />
        <Route path="course-price" element={<AddToCart />} />
        <Route path="abacus-campaign" element={<AbacusCampaign />} />
        <Route path="abacus-campaing-leads" element={<AbacusCampaignLeads />} />
        <Route path="eft&ela-leads" element={<EftRegistrationList />} />
        <Route path="quiz-result" element={<Result />} />
        <Route path="english-fluency-test" element={<EFT />} />
        <Route path="download-leads" element={<DownloadRegistrationLeads />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
