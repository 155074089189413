import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const NotificationShowModal = ({ show, onHide, notificationdetails }) => {
  const [shareableImageUrl, setShareableImageUrl] = useState("");
  const [bodyData, setBodyData] = useState("");

  const title = notificationdetails?.notification?.title || "No title";
  const body = notificationdetails?.notification?.body || "No body";
  const imageUrl =
    notificationdetails?.notification?.image &&
    `${notificationdetails?.image_url}${notificationdetails?.notification?.image}`;

  useEffect(() => {
    if (imageUrl) {
      setShareableImageUrl(imageUrl);
    }
    const jsonString = JSON.stringify(title);
    setBodyData(jsonString);
  }, [imageUrl, title]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={onHide}
      contentLabel="Notification Modal"
      // style={{
      //   overlay: {
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   },
      // content: {
      //   maxWidth: "60%",
      //   margin: "auto",
      //   padding: "20px",
      //   borderRadius: "12px",
      //   maxHeight:"90%"
      // },
      // content: {
      //   maxWidth: "60%",
      //   maxHeight: "80%",
      //   margin: "auto",
      //   padding: "20px",
      //   borderRadius: "12px",
      //   overflowY: "auto",
      // },
      // }}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          maxWidth: "60%",
          width: "auto",
          height: "auto",
          maxHeight:"75vh",
          margin: "auto",
          padding: "24px",
          borderRadius: "16px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          justifyContent: "center",
          background: "#fff",
        },
      }}
      ariaHideApp={false}
    >
      <button
        onClick={onHide}
        // style={{
        //   float: "right",
        //   border: "none",
        //   background: "transparent",
        //   fontSize: "20px",
        //   cursor: "pointer",
        // }}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          border: "none",
          background: "transparent",
          fontSize: "24px",
          cursor: "pointer",
          color: "#555",
        }}
      >
        ×
      </button>

      <h2 className="">{title}</h2>
      {imageUrl && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "100vw",
            maxHeight: "100vh",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <img
            src={imageUrl}
            alt="Notification"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              borderRadius: "12px",
            }}
          />
        </div>
      )}
      {/* {imageUrl && (
        <div className="d-flex justify-content-center mb-3">
          <img
            src={imageUrl}
            alt="Notification"
            className="img-fluid"
            style={{
              borderRadius: "12px",
              width: "100%",
              maxHeight: "60%",
              objectFit: "contain",
            }}
          />
        </div>
      )} */}
      <p style={{ fontSize: "18px" }}>{body}</p>
    </Modal>
  );
};

export default NotificationShowModal;

// =========================THis is perfect======================
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   WhatsappIcon,
//   FacebookMessengerShareButton,
//   FacebookMessengerIcon,
//   WhatsAppIcon,
//   WhatsAppShareButton,
// } from "react-share";
// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";

// const NotificationShowModal = (props) => {
//   const { notificationdetails } = props;
//   const [shareableImageUrl, setShareableImageUrl] = useState("");
//   const [bodyData, setBodyData] = useState("");

//   const title = notificationdetails?.notification?.title || "No title";
//   const body = notificationdetails?.notification?.body || "No body";
//   const imageUrl =
//     notificationdetails?.notification?.image &&
//     `${notificationdetails?.image_url}${notificationdetails?.notification?.image}`;

//   // Set image URL for sharing when it is available
//   useEffect(() => {
//     if (imageUrl) {
//       setShareableImageUrl(imageUrl); // Set the image URL
//     }
//     const jsonString = JSON.stringify(title);
//     setBodyData(jsonString);
//   }, [imageUrl, title]);

//   const isUrlValid = shareableImageUrl && shareableImageUrl.trim().length > 0;

//   return (
//     <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {imageUrl &&
//           (imageUrl ? (
//             <div className="d-flex justify-content-center mb-3">
//               <img
//                 src={imageUrl}
//                 alt="Notification"
//                 className="img-fluid"
//                 style={{ borderRadius: "12px" }}
//               />
//             </div>
//           ) : (
//             <p>Image not loaded</p>
//           ))}

//         <div>
//           <p style={{ fontSize: "18px" }}>{body}</p>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default NotificationShowModal;
// =========================The above things is perfect======================
// {/* <Modal.Footer>
// {/* Facebook Share Button */}
// {/* {isUrlValid && ( */}
// <FacebookShareButton
//   url={shareableImageUrl}
//   quote={`${title}\n${body}`}
//   hashtag={`${bodyData}`}
//   // hashtag={`#${bodyData}`}
// >
//   <FacebookIcon size={40} round />
// </FacebookShareButton>
// {/* )} */}

// {/* WhatsApp Share Button */}
// <WhatsappShareButton
//   url="https://mojaru.com/"
//   title={`${title}\n${body}`}
// >
//   <WhatsappIcon size={40} round />
// </WhatsappShareButton>
// </Modal.Footer> */}
// ===================================================================================================

//////=============> Worked
// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   WhatsappIcon,
// } from "react-share";

// const NotificationShowModal = (props) => {
//   const { notificationdetails } = props;
//   const [shareableImageUrl, setShareableImageUrl] = useState("");

//   const title = notificationdetails?.notification?.title || "No title";
//   const body = notificationdetails?.notification?.body || "No body";
//   const imageUrl =
//     notificationdetails?.notification?.image &&
//     `${notificationdetails?.image_url}${notificationdetails?.notification?.image}`;

//   // Simulate setting the URL for sharing
//   useEffect(() => {
//     if (imageUrl) {
//       setShareableImageUrl(imageUrl); // Assuming the imageUrl is public and sharable
//     }
//   }, [imageUrl]);

//   const isUrlValid = shareableImageUrl && shareableImageUrl.trim().length > 0;

//   return (
//     <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="d-flex justify-content-center mb-3">
//           {imageUrl ? (
//             <img
//               src={imageUrl}
//               alt="Notification"
//               className="img-fluid"
//               style={{ borderRadius: "12px" }}
//             />
//           ) : (
//             <p>No image available</p>
//           )}
//         </div>
//         <div>
//           <p style={{ fontSize: "18px" }}>{body}</p>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         {isUrlValid && (
//           <>
//             {/* Facebook Share */}
//             <FacebookShareButton quote={`${title}\n${body}`}>
//               <FacebookIcon size={40} round />
//             </FacebookShareButton>

//             {/* Twitter Share */}
//             <TwitterShareButton
//               url={shareableImageUrl}
//               title={`${title}\n${body}`}
//             >
//               <TwitterIcon size={40} round />
//             </TwitterShareButton>

//             {/* WhatsApp Share */}
//             <WhatsappShareButton
//               url={shareableImageUrl}
//               title={`${title}\n${body}`}
//             >
//               <WhatsappIcon size={40} round />
//             </WhatsappShareButton>
//           </>
//         )}
//         {!isUrlValid && <p>Preparing shareable content...</p>}
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default NotificationShowModal;
// =====================================================================================================
// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   WhatsappIcon,
// } from "react-share";

// const NotificationShowModal = (props) => {
//   const { notificationdetails } = props;
//   const [url, setUrl] = useState("");
//   const shareUrl =
//     notificationdetails?.notification?.image &&
//     `${notificationdetails?.image_url}${notificationdetails?.notification?.image}`; // A public URL to host this notification
//   const title = notificationdetails?.notification?.title || "No title";
//   const body = notificationdetails?.notification?.body || "No body";
//   const image =
//     notificationdetails?.notification?.image &&
//     `${notificationdetails?.image_url}${notificationdetails?.notification?.image}`;

//   useEffect(() => {
//     setUrl(notificationdetails?.notification?.image);
//   }, [notificationdetails?.notification?.image]);

//   // Open Graph metadata needs to be added to "shareUrl"
//   return (
//     <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="d-flex justify-content-center mb-3">
//           {image ? (
//             <img
//               src={image}
//               alt="Notification"
//               className="img-fluid"
//               style={{ borderRadius: "12px" }}
//             />
//           ) : (
//             ""
//           )}
//         </div>
//         <div>
//           <p style={{ fontSize: "18px" }}>{body}</p>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         {/* Facebook Share */}
//         {/* <FacebookShareButton
//           url={shareUrl} // URL containing Open Graph metadata for this notification
//           quote={`${title}\n${body}`}
//         >
//           <FacebookIcon size={40} round />
//         </FacebookShareButton> */}

//         <FacebookShareButton url={shareUrl}>
//           <FacebookIcon />
//         </FacebookShareButton>

//         {/* Twitter Share */}
//         <TwitterShareButton url={shareUrl} title={`${title}\n${body}`}>
//           <TwitterIcon size={40} round />
//         </TwitterShareButton>

//         {/* WhatsApp Share */}
//         <WhatsappShareButton url={shareUrl} title={`${title}\n${body}\n${image}`}>
//           <WhatsappIcon size={40} round />
//         </WhatsappShareButton>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default NotificationShowModal;

// =============================================================

// // import React from 'react';
// // import { Modal } from 'react-bootstrap';

// // const NotificationShowModal = (props) => {
// //     return (
// //         <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
// //             <Modal.Header closeButton>
// //                 <Modal.Title id="contained-modal-title-vcenter">
// //                     {props?.notificationdetails?.notification?.title}
// //                 </Modal.Title>
// //             </Modal.Header>
// //             <Modal.Body>
// //                 <div className='d-flex justify-content-center mb-3'>
// //                     {
// //                         props?.notificationdetails?.notification?.image === null ? '' : <img src={`${props?.notificationdetails?.image_url}${props?.notificationdetails?.notification?.image}`} alt="" className='img-fluid' style={{ borderRadius:'12px' }} />
// //                     }
// //                 </div>
// //                 <div>
// //                     <p style={{ fontSize:'18px' }}>{props?.notificationdetails?.notification?.body}</p>
// //                 </div>
// //                 <div dangerouslySetInnerHTML={{__html: props?.notificationdetails?.notification?.others}}/>
// //                 {/* <span style={{ fontSize:'18px' }}>{props?.notificationdetails?.notification?.push_notification_message}</span> */}
// //             </Modal.Body>
// //         </Modal>
// //     );
// // };

// // export default NotificationShowModal;
